<template>
    <v-card>
        <v-card-title
            class="primary white--text text-subtitle-2"
            v-if="!hideTitle"
        >
            Household Rental Payment History
            <v-spacer></v-spacer>
            <v-btn icon dark @click.stop="$emit('close:dialog')"
                ><v-icon small>mdi-close</v-icon></v-btn
            >
        </v-card-title>
        <v-card-text>
            <v-simple-table class="mt-2">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left" width="160">Applicant</th>
                            <th class="text-left" width="240">Address</th>
                            <th class="text-left">Description</th>
                            <th class="text-center">Dates</th>
                            <th class="text-right">Monthly Payment</th>
                            <th class="text-center">Verified</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                            <td>{{ item.applicant }}</td>
                            <td>
                                {{ item.address }}<br />{{
                                    item.city_state_zip
                                }}
                            </td>
                            <td class="text-left">{{ item.description }}</td>
                            <td class="text-center">{{ item.dates }}</td>
                            <td class="text-right">
                                {{ $dollarFormat(item.monthly_payment) }}
                            </td>
                            <td class="text-center">
                                <v-icon v-if="item.verified" color="primary"
                                    >mdi-check-circle</v-icon
                                ><v-icon
                                    v-else
                                    class="grey--text text--lighten-2"
                                    >mdi-check-circle</v-icon
                                >
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "screening-household-summary-income",
    components: {},
    props: {
        items: {
            type: Array,
            required: true,
        },
        hideTitle: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {},
};
</script>

<style scoped>
</style>
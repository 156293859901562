var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      !_vm.hideTitle
        ? _c(
            "v-card-title",
            { staticClass: "primary white--text text-subtitle-2" },
            [
              _vm._v(" Household Rental Payment History "),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.$emit("close:dialog")
                    }
                  }
                },
                [_c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card-text",
        [
          _c("v-simple-table", {
            staticClass: "mt-2",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [
                    _c("thead", [
                      _c("tr", [
                        _c(
                          "th",
                          { staticClass: "text-left", attrs: { width: "160" } },
                          [_vm._v("Applicant")]
                        ),
                        _c(
                          "th",
                          { staticClass: "text-left", attrs: { width: "240" } },
                          [_vm._v("Address")]
                        ),
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v("Description")
                        ]),
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v("Dates")
                        ]),
                        _c("th", { staticClass: "text-right" }, [
                          _vm._v("Monthly Payment")
                        ]),
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v("Verified")
                        ])
                      ])
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.items, function(item, index) {
                        return _c("tr", { key: index }, [
                          _c("td", [_vm._v(_vm._s(item.applicant))]),
                          _c("td", [
                            _vm._v(" " + _vm._s(item.address)),
                            _c("br"),
                            _vm._v(_vm._s(item.city_state_zip) + " ")
                          ]),
                          _c("td", { staticClass: "text-left" }, [
                            _vm._v(_vm._s(item.description))
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(item.dates))
                          ]),
                          _c("td", { staticClass: "text-right" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$dollarFormat(item.monthly_payment)
                                ) +
                                " "
                            )
                          ]),
                          _c(
                            "td",
                            { staticClass: "text-center" },
                            [
                              item.verified
                                ? _c(
                                    "v-icon",
                                    { attrs: { color: "primary" } },
                                    [_vm._v("mdi-check-circle")]
                                  )
                                : _c(
                                    "v-icon",
                                    {
                                      staticClass: "grey--text text--lighten-2"
                                    },
                                    [_vm._v("mdi-check-circle")]
                                  )
                            ],
                            1
                          )
                        ])
                      }),
                      0
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }